import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  Modal,
  Backdrop,
  TablePagination,
  List,
  ListItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAuth0 } from "@auth0/auth0-react";
import { useGroup } from "../../contexts/GroupContext";
import { useResponsive } from "../../contexts/ResponsiveContext";
import axios from "axios";
import { format } from "date-fns";
import EmailSortOrderButton from "./EmailSortOrderButton";
import { ReactComponent as CalenderIcon } from "../../assets/icons/CalenderIcon.svg";

const EmailHistoryPanel = () => {
  const { isMobile } = useResponsive();
  const [emailHistories, setEmailHistories] = useState<
    { emailId: number; sentAt: string; html: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedEmailHTML, setSelectedEmailHTML] = useState<string | null>(
    null
  ); // 選択されたメール
  const [selectedEmailDate, setSelectedEmailDate] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [sortOrder, setSortOrder] = useState<"newest" | "oldest">("newest");
  const [emailHtmlCache, setEmailHtmlCache] = useState<Record<string, string>>(
    {}
  );
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
  const startAnchorRef = useRef<HTMLDivElement | null>(null);
  const endAnchorRef = useRef<HTMLDivElement | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const { groupId } = useGroup();

  // ページネーション用の状態
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchEmailOverviews = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();

        const query = `
          query GetEmailHistoryOverview($groupId: Int!) {
            emailHistoryOverview(groupId: $groupId) {
              emailId
              subject
              sentAt
            }
          }
        `;

        const variables = { groupId: groupId };
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/graphql`,
          { query, variables },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data?.data?.emailHistoryOverview) {
          const histories = response.data.data.emailHistoryOverview;
          setEmailHistories(histories);
          setError(null);

          // デフォルトの開始日と終了日を設定
          if (histories.length > 0) {
            const sortedByDate = [...histories].sort(
              (a, b) =>
                new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime()
            );
            setStartDate(new Date(sortedByDate[0].sentAt));
            setEndDate(new Date(sortedByDate[sortedByDate.length - 1].sentAt));
          }
        } else {
          setError("データが見つかりませんでした。");
        }
      } catch (err) {
        console.error(err);
        setError("メール履歴の取得に失敗しました。");
      } finally {
        setLoading(false);
      }
    };

    if (groupId) {
      fetchEmailOverviews();
    }
  }, [getAccessTokenSilently, groupId]);

  const handleEmailClick = async (emailId: number) => {
    const selectedEmailDate = format(
      new Date(
        emailHistories.find((email) => email.emailId === emailId)?.sentAt || ""
      ),
      "yyyy-MM-dd"
    );
    setSelectedEmailDate(selectedEmailDate);
    if (emailHtmlCache[emailId]) {
      setSelectedEmailHTML(emailHtmlCache[emailId]);
      prefetchPreviousEmail(emailId); // 前のメールをバックグラウンドで取得
      return;
    }
    setLoadingEmail(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        {
          query: `
            query GetEmailHtml($emailId: Int!) {
              emailHtml(emailId: $emailId)
            }
          `,
          variables: { emailId },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data?.data?.emailHtml) {
        const html = response.data.data.emailHtml;
        setEmailHtmlCache((prev) => ({ ...prev, [emailId]: html }));
        setSelectedEmailHTML(html);
        prefetchPreviousEmail(emailId); // 前のメールをバックグラウンドで取得
      } else {
        setError("メールHTMLを取得できませんでした。");
      }
    } catch (err) {
      console.error(err);
      setError("メールHTMLの取得に失敗しました。");
    } finally {
      setLoadingEmail(false);
    }
  };

  const prefetchPreviousEmail = async (currentEmailId: number) => {
    const currentIndex = sortedHistories.findIndex(
      (email) => email.emailId === currentEmailId
    );
    // 一つ前のメールが存在するか確認
    if (currentIndex + 1 < sortedHistories.length) {
      const previousEmail = sortedHistories[currentIndex + 1];

      // 既にキャッシュ済みの場合は何もしない
      if (emailHtmlCache[previousEmail.emailId]) {
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/graphql`,
          {
            query: `
              query GetEmailHtml($emailId: Int!) {
                emailHtml(emailId: $emailId)
              }
            `,
            variables: { emailId: previousEmail.emailId },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data?.data?.emailHtml) {
          const html = response.data.data.emailHtml;
          setEmailHtmlCache((prev) => ({
            ...prev,
            [previousEmail.emailId]: html,
          }));
        }
      } catch (err) {
        console.error("バックグラウンドでのメール取得に失敗しました。", err);
      }
    }
  };

  const handleSortChange = () => {
    setSortOrder((prev) => (prev === "newest" ? "oldest" : "newest"));
    setPage(0); // ソート変更時にページをリセット
  };

  const filteredHistories = emailHistories.filter((email) => {
    const emailDate = new Date(email.sentAt).setHours(0, 0, 0, 0);
    const startTime = startDate
      ? new Date(startDate).setHours(0, 0, 0, 0)
      : null;
    const endTime = endDate ? new Date(endDate).setHours(0, 0, 0, 0) : null;

    return (
      (!startTime || emailDate >= startTime) &&
      (!endTime || emailDate <= endTime)
    );
  });

  const sortedHistories = [...filteredHistories].sort((a, b) => {
    return sortOrder === "newest"
      ? new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime()
      : new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime();
  });

  // ページネーションを適用した履歴
  const paginatedHistories = sortedHistories.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // ページ変更ハンドラー
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  // 行数変更ハンドラー
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // 表示件数が変わったらページをリセット
  };

  const handleCloseEmailModal = () => {
    setSelectedEmailHTML(null);
    setSelectedEmailDate("");
  };

  const EmptyIcon = () => <></>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          padding: isMobile ? "24px 16px 24px 16px" : 5,
          backgroundColor: "#FFF",
          borderRadius: "var(--1, 8px)",
          flexGrow: 1,
          minWidth: "100%",
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        {/* 最上部 */}
        <Box
          mb={isMobile ? 0 : "8px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: isMobile ? "16px" : 4,
          }}
        >
          {/* タイトルとダウンロードボタン */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#17171A",
                fontSize: "24px",
                fontWeight: 700,
                flex: "1 0 0",
              }}
            >
              メール配信履歴
            </Typography>
            {!isMobile && (
              <Button variant="contained" color="primary" disabled={true}>
                CSVをダウンロード
              </Button>
            )}
          </Box>

          {/* サブタイトルと検索フィールド */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* 左側: フィルタ */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: isMobile ? "100%" : "860px",
              }}
            >
              {/* 左側: フィルタ */}
              <Box sx={{ display: "flex", gap: isMobile ? "8px" : "16px" }}>
                {/* 開始日 */}
                <Box
                  ref={startAnchorRef}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                  }}
                >
                  <DatePicker
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    format="yyyy-MM-dd"
                    slotProps={{
                      popper: {
                        anchorEl: startAnchorRef.current,
                        disablePortal: true,
                      },
                      openPickerButton: {
                        // IconButtonのスタイルをオーバーライド
                        sx: {
                          padding: 0,
                          display: "flex",
                        },
                      },
                    }}
                    slots={{
                      openPickerIcon: isMobile ? EmptyIcon : CalenderIcon,
                      textField: (props) => (
                        <TextField
                          {...props}
                          sx={{
                            width: isMobile ? "128px" : "136px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              color: "var(--BK-500, #9797A3)",
                              fontFeatureSettings: "'liga' off, 'clig' off",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "var(--fontWeightRegular, 400)",
                              lineHeight: "140%", // 19.6px
                              letterSpacing: "0.28px",
                              padding: "8px 12px",
                            },
                          }}
                        />
                      ),
                    }}
                  />
                </Box>

                {/* 終了日 */}
                <Box
                  ref={endAnchorRef}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                  }}
                >
                  <DatePicker
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate ? startDate : undefined}
                    format="yyyy-MM-dd"
                    slotProps={{
                      popper: {
                        anchorEl: endAnchorRef.current,
                        disablePortal: true,
                      },
                      openPickerButton: {
                        // IconButtonのスタイルをオーバーライド
                        sx: {
                          padding: 0,
                          display: "flex",
                        },
                      },
                    }}
                    slots={{
                      openPickerIcon: isMobile ? EmptyIcon : CalenderIcon,
                      textField: (props) => (
                        <TextField
                          {...props}
                          sx={{
                            width: isMobile ? "120px" : "136px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              color: "var(--BK-500, #9797A3)",
                              fontFeatureSettings: "'liga' off, 'clig' off",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "var(--fontWeightRegular, 400)",
                              lineHeight: "140%", // 19.6px
                              letterSpacing: "0.28px",
                              padding: "8px 12px",
                            },
                          }}
                        />
                      ),
                    }}
                  />
                </Box>
              </Box>
              {/* 右側: 並び順 */}
              <EmailSortOrderButton
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            </Box>
          </Box>
        </Box>

        {/* メール履歴リスト */}
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : sortedHistories.length === 0 ? (
          <Typography>メール履歴がありません。</Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: isMobile ? "100%" : "860px",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRadius: "4px",
              border: "1px solid var(--BK-300, #CBCBD0)",
              background: "var(--background-paper-elevation-1, #FFF)",
              overflow: "hidden",
            }}
          >
            {/* テーブルヘッダー */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                padding: "8px 16px",
                borderBottom: "1px solid #E0E0E0",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#17171A",
                  fontSize: "14px",
                }}
              >
                日付
              </Typography>
            </Box>

            {/* テーブルボディ */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <List sx={{ padding: 0 }}>
                {paginatedHistories.map((email) => (
                  <ListItem
                    key={email.emailId}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderBottom: "1px solid #EEEEF0",
                      cursor: "pointer",
                      padding: "8px 16px",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                    onClick={() => handleEmailClick(email.emailId)}
                  >
                    <Typography
                      sx={{
                        color: "#0288D1",
                        fontSize: "14px",
                      }}
                    >
                      {format(new Date(email.sentAt), "yyyy-MM-dd")}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* ページネーション */}
            <TablePagination
              component="div"
              count={sortedHistories.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10]}
              labelRowsPerPage="" // ラベルを空にする場合はこの行を追加
              sx={{ width: "100%" }}
            />
          </Box>
        )}

        {/* モーダル: メール内容 */}
        <Modal
          open={!!selectedEmailHTML}
          onClose={handleCloseEmailModal}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#FFF",
              borderRadius: "8px",
              maxWidth: "80%",
              maxHeight: "80%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            {/* メールの日付とタイトル、閉じるボタン */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: "16px 24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography sx={{ fontSize: "14px", color: "#17171A" }}>
                  {selectedEmailDate}
                </Typography>
                <Typography
                  sx={{
                    color: "#656572",
                    fontSize: "14px",
                  }}
                >
                  の配信履歴を表示しています
                </Typography>
              </Box>
              <Button
                onClick={handleCloseEmailModal}
                sx={{
                  minWidth: "32px",
                  minHeight: "32px",
                  padding: 0,
                  background: "transparent",
                  color: "#777",
                  fontSize: "16px",
                  lineHeight: "1",
                  borderRadius: "50%",
                  "&:hover": { backgroundColor: "#f5f5f5" },
                }}
              >
                ✕
              </Button>
            </Box>
            {/* メール内容 */}
            <Box
              dangerouslySetInnerHTML={{ __html: selectedEmailHTML || "" }}
              sx={{
                padding: "0px 40px"
              }}
            />
          </Box>
        </Modal>
        {/* Loading Spinner */}
        <Backdrop
          open={loading || loadingEmail}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </LocalizationProvider>
  );
};

export default EmailHistoryPanel;
